import * as React from 'react'
import { shape, string } from 'prop-types'
import { ErrorPage } from "@/utils/ErrorPage";
function SSOError({ location }) {
	return (
		<ErrorPage header={"Unable to log in"} body={"Sorry, there was a problem signing in to your account"} />
	)
}

SSOError.propTypes = {
	location: shape({
		pathname: string
	}).isRequired
}

export default SSOError
